@import "/src/styles/shared/common";
.accordion-open {
  color: $brand-color !important;
}
.accordion-close {
  color: $blue !important;
}
// .accordion-outer-container {
//   background-color: $background;
// }
.accordion-container {
  display: flex;
  margin: 3rem auto;
  gap: 2rem;
  width: 100%;
}
.accordion {
  border: 1px solid $border !important;
}
.accordion-section-left {
  width: 30%;
  & span {
    color: $highContractText;
    margin-left: 5px;
  }
}
.accordion-section-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 70%;
}
.accordion-header {
  padding: 1rem 0 !important;
  transition: transform 0.3s;
}

.accordion-item {
  border: 1px solid $border !important;
  border-radius: 10px;
  &-button {
    display: flex;
    width: 95%;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 1rem 1.5rem;
    border: none;
    background: none;
  }
  &-question {
    @extend .paragraphTitle;
    padding: 0 !important;
  }
  &-answer {
    @extend .para;
    padding: 1.5rem !important;
  }
  & svg {
    color: $blue;
  }
}
@media screen and (max-width: 768px) {
  .accordion-container {
    flex-direction: column;
  }
  .accordion-section-left {
    width: 100%;
  }
  .accordion-section-right {
    width: 100%;
  }
  .accordion-header {
    font-size: 1rem !important;
  }
  .accordion-item {
    &-question {
      font-size: 15px !important;
      line-height: 1.3;
    }
    &-button {
      width: 90%;
    }
  }
}
