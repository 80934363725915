@import "../../styles/shared/common";
.resourcesListContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  margin: 3rem 0;
}

.resources-filter-title {
  display: flex;
  gap: 10px;
  align-items: center;
  & svg {
    color: $blue;
  }
}

.resources-filter-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem 0;
  gap: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid #c7cfe4;
}
.resources-filter-dropdown-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.filtered-list {
  display: flex;
  gap: 20px;
  padding: 20px;
  border: 1px solid #cfcfdf;
  border-radius: 15px;
  margin-bottom: 20px;
}

.filtered-list-item {
  background-color: #eef0f5;
  padding: 10px 20px;
  border-radius: 30px;
  color: $blue;
  display: flex;
  align-items: center;
  gap: 5px;
}

.resource-card-resource {
  margin-top: 30px;
}
.resource-card {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
  border-radius: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  box-shadow: rgb(234, 234, 234) 3px 3px 6px 0px,
    rgba(255, 255, 255, 0.5) -3px 3px 6px 1px;
}
.resource-card .resource-card-image {
  height: 60%;
  position: relative;
  background: #f1f2f5;
  overflow: hidden;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border-radius: 15px;
}
.resource-card .resource-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  pointer-events: none;
}

.resource-industry-country-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}
.resource-industry,
.resource-country {
  background-color: #ffffff36;
  backdrop-filter: blur(11px);
  padding: 3px 10px;
  border-radius: 5px;
  color: #ffffff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border: 1px solid #ffffff11;
}
.resources-filter-dropdown {
  width: auto;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255);
  border: 1px solid #bbc4df;
  position: relative;
}

.resources-filter-dropdown-header {
  padding: 15px 25px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  color: $blue;
}

.resources-filter-dropdown-body {
  min-width: 200px;
  padding: 5px;
  border-top: 1px solid #e5e8ec;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  background-color: white;
  border: 1px solid #bbc4df;
  display: none;
  border-radius: 10px;
  text-align: left;
}

.resources-filter-dropdown-body.open {
  display: block;
  position: absolute;
  top: 60px;
  z-index: 1;
}

.resources-filter-dropdown-item {
  padding: 10px;
  &:hover {
    cursor: pointer;
    background-color: #e5e8efa2;
    border-radius: 10px;
  }
}

.resources-filter-dropdown-item:hover {
  cursor: pointer;
}
.resource-card .resource-content {
  padding: 15px;
}

.resource-content {
  margin-bottom: 0px;
}

.resource-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resource-category {
  position: relative;
  line-height: 0;
  margin: 15px 0;
}
.resource-footer-time svg {
  vertical-align: text-bottom;
}
.resource-text-success {
  color: $brand-color !important;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}
.resource-card-resource .resource-card-caption {
  margin-top: 5px;
}
.resource-card-caption-container {
  width: 90%;
}
.resource-card-caption {
  @extend .para;
  font-size: 20px;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.resource-card-description {
  @extend .para;
  color: $light-blue;
  margin-top: 0;
  margin-bottom: 1rem;
}


.resource-footer-button a {
  text-decoration: none;
  color: $blue;
  font-weight: 600;
  font-size: 16px;
}

.resource-footer-time {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $light-grey;
  font-size: 14px;
}

//// resource banner ////
///
.resource-banner {
  max-width: 100%;
  padding: 4rem 0;
  background-image: linear-gradient(
    to right,
    #f4feff,
    #f6fcff,
    #fbfaff,
    #fef9fc,
    #fef8f8
  );
  &-title {
    @extend .bannerTitle;
    text-align: start;
    font-size: 40px;
    width: 80%;
  }
  &-info {
    display: flex;
    gap: 20px;
    margin: 20px 0;
    font-size: 13px;
    &-date {
      color: #606060;
      font-weight: 300;
      display: flex;
      align-items: center;
      gap: 4px;
      &:hover {
        color: $brand-color;
      }
    }
    &-date svg {
      vertical-align: text-bottom;
    }

    &-type {
      display: flex;
      align-items: center;
      gap: 3px;
      font-weight: 300;
      color: #606060;
      &:hover {
        color: $brand-color;
      }
    }
  }
}

//// resource details items page ////
.resource-details-items-container {
  margin-top: 40px;
}
.resource-details-items-content-container {
  width: 100%;
}

.resource-details-items {
  &-title {
    @extend .bannerTitle;
    text-align: start;
    font-size: 40px;
    width: 80%;
  }
  &-more-list {
    display: flex;
    gap: 10px;
    flex-direction: column;
    &-item {
      a {
        display: flex;
        align-items: center;
        gap: 20px;
        text-decoration: none;
        box-shadow: 0 2px 7px 0 #14142b0f;
        background-color: #fff;
        border-radius: 20px;
        padding: 1rem;
        border: 1px solid #edeff3;
        margin-top: 1rem;
      }
      img {
        width: 35%;
        height: 110px;
        object-fit: cover;
        border-radius: 10px;
        &:hover {
          scale: 1.04;
          transition: ease-in-out 0.3s;
        }
      }
      &-title {
        @extend .paragraphTitle;
        //// show only 2 lines of text ////
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Number of lines to show */
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  &-info {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 20px 0;
    font-size: 16px;

    &-date {
      color: #606060;
      font-weight: 300;
      display: flex;
      align-items: center;
      gap: 4px;
      &:hover {
        color: $brand-color;
      }
    }

    &-type {
      display: flex;
      align-items: center;
      gap: 3px;
      font-weight: 300;
      color: #606060;
      &:hover {
        color: $brand-color;
      }
    }

    &-author {
      display: flex;
      align-items: center;
      gap: 3px;
      font-weight: 300;
      color: #606060;
      &:hover {
        color: $brand-color;
      }
    }
  }
}

.resource-details-items-content {
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
  }
  &-title {
    @extend .bannerTitle;
    font-size: 42px;
    color: $blue;
    text-align: center;
    margin: 50px 50px 0 50px;
  }
  &-data {
    div {
      margin: 25px 0;
    }
    p {
      line-height: 1.9;
    }
    &-container {
      display: grid;
      grid-column-gap: 64px;
      grid-template-columns: 1fr 0.65fr;
      align-items: start;
      width: 100%;
      padding: 50px 0;
    }
  }
  &-social-share {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-weight: 600;
    @extend .para;
    &-btn {
      display: flex;
      gap: 10px;
    }
  }
}

.copy-link-container {
  width: 80%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $border;
  padding: 5px;
  border-radius: 10px;

  input {
    border: none;
    outline: none;
    width: 85%;
    color: $blue;
    margin: 10px;
  }
  button {
    background-color: $blue;
    padding: 10px 20px !important;
    border: none;
    color: white;
    border-radius: 10px !important;
  }
}
.resource-details-items-content ul,
.resource-details-items-content ol {
  padding-left: 2rem;
  & li {
    @extend .para;
  }
}

.resource-details-items-content-image {
  border-radius: 20px;
  width: 100%;
  height: 600px;
  img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &-webinar {
    background-color: #f1f2f5;
  }
}

.resource-details-items-content-button {
  width: fit-content;
  padding: 4px 15px;
  margin: 15px 0;
}
.resource-details-items-content-reg-link {
  text-align: center;
}
.resource-detailsbody-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
  display: flex;
  margin: auto !important;
  img {
    width: 100%;
    height: auto;
    margin: auto;
    border-radius: 20px;
  }
}
////// table styles/ /////
///

th {
  @extend .paragraphTitle;
}
td {
  @extend .para;
  padding: 0.5rem 1rem !important;
}

td img {
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .resourcesListContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  .resource-details-items-content-data-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 768px) {
  .resourcesListContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .resource-card-caption-container {
    width: 100%;
  }

  /// resource banner mobile view ///
  .resource-banner {
    // padding: 2rem 1rem;
    &-title {
      width: 95%;
    }
    &-info {
      display: flex;
      flex-direction: column;
    }
  }
  /// resource details mobile view ///
  .resources-filter-container {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }

  //// Resource Details page mobile view ////
  .resource-details-items-container {
    margin-top: 0;
  }
  .resource-details-items-sidebar {
    width: 100%;
  }
  .resource-details-items-content {
    width: 95%;
    padding: 10px;
    margin-top: -20px;
    &-social-share {
      flex-direction: column;
      gap: 10px;
    }
  }
  .resource-details-items-content-image {
    border-radius: 20px;
    width: 100%;
    height: auto;
    img {
      object-fit: contain;
      border-radius: 15px;
      height: auto;
    }
  }
  .resource-details-items-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  .resource-details-items-content-data-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
