.carousel-container {
    width: 100%;
    max-width: 1224px;
    margin: 0 auto;
    padding: 1.5rem;
  }
  
  .carousel {
    position: relative;
    background: white;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  
    &__slide {
      display: flex;
      align-items: center;
      padding: 2rem;
    }
  
    &__image-container {
      width: 33.333%;
      padding: 1rem;
    }
  
    &__image {
      width: 100%;
      height: 20rem;
      object-fit: cover;

      border-radius: 0.5rem;
    }
  
    &__content {
      width: 66.666%;
      padding: 1rem;
    }
  
    &__quote {
      font-size: 1.125rem;
      font-style: italic;
      color: #4a5568;
      margin-bottom: 1.5rem;
      line-height: 1.6;
    }
  
    &__member-info {
      margin-top: 1rem;
    }
  
    &__name {
      font-size: 1.25rem;
      font-weight: 600;
      color: #2d3748;
      margin-bottom: 0.25rem;
    }
  
    &__role {
      color: #718096;
      font-size: 1rem;
    }
  
    &__nav-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: white;
      border: none;
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: background-color 0.2s;
  
      &:hover {
        background-color: #f7fafc;
      }
  
      &--prev {
        left: 1rem;
      }
  
      &--next {
        right: 1rem;
      }
  
      svg {
        width: 1.5rem;
        height: 1.5rem;
        color: #4a5568;
      }
    }
  
  
    &__dots {
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      gap: 0.5rem;
    }
  
    &__dot {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: #cbd5e0;
      border: none;
      padding: 0;
      cursor: pointer;
      transition: background-color 0.2s;
  
      &--active {
        background-color: #3182ce;
      }
    }
  }
  
  // Add slide transition
  .carousel__slide {
    transition: transform 0.3s ease-in-out;
  }
  
  // Responsive styles
  @media (max-width: 768px) {
    .carousel {
      &__slide {
        flex-direction: column;
      }
  
      &__image-container,
      &__content {
        width: 100%;
      }
  
      &__image {
        height: 12rem;
      }
  
      &__quote {
        font-size: 1rem;
      }
    }
  }