@import "../../styles/shared/common";
.features-container {
  display: flex;
  align-items: center;
}
.features-details-wrapper {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.features-details-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 15px;
}

.features-content-section-title {
  @extend .sectionMainTitle;
  color: $brand-color;
}
.features-content-title {
  @extend .sectionTitle;
  text-align: start;
}

.features-details-card {
  width: 200px;
  height: 150px;
  border-radius: 15px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 5px;
  }
}

.features-details-card-even {
  margin-top: 15px;
  box-shadow: $box-shadow;
}
.features-details-card-add {
  box-shadow: $box-shadow;
}

.features-content-description {
  @extend .para;
  padding-top: 10px !important;
}

.features-content-items {
  @extend .para;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
  padding-top: 15px !important;
}
.features-content-items div {
  display: flex;
  gap: 10px;
}

.features-details-card-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.features-card-description {
  @extend .para;
  font-weight: 450;
  font-size: 14px;
}

.features-card-title {
  color: $blue;
  font-weight: 550;
}

@media screen and (max-width: 768px) {
  .features-container {
    flex-direction: column-reverse;
  }

  .features-details-container {
    padding-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }
}

@media screen and (max-width: 1280px) {
  .features-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .features-details-container {
    width: fit-content;
  }
}
@media screen and (max-width: 350px) {
  .features-content-items {
    grid-template-columns: 1fr;
  }
}
