.desktopMenu {
  display: block;
  padding: 10px;
}

.header-top-container {
  display: block;
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  background-color: #343e61;
  padding: 10px 10px;
  color: #fff;
  font-weight: 400;
  padding-right: 4%;
  font-size: 14px;
  & a {
    text-decoration: none;
    color: #ffffff;
  }
}

.header-top-announcement-text {
  & a {
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
}
.header-top-right {
  display: flex;
  gap: 10px;
  width: 70%;
  justify-content: space-between;
  align-items: center;
}
.header-top-info {
  display: flex;
  gap: 10px;
  align-items: center;
}
.header-top-location-dropdown-toggle {
  cursor: pointer;
}
.header-top-location-dropdown-menu {
  padding: 10px 15px;
  border-radius: 10px;
  position: absolute;
  z-index: 999;
  background-color: #343e61;
  margin-top: 15px;
  & div {
    padding: 2px 0;
    cursor: pointer;
  }
}

/* Hide header-top-container */
.header-hidden .header-top-container {
  transform: translateY(-100%);
  display: none;
  transition: transform 0.6s;
}

.burgerMenu {
  display: none;
  padding: 1rem;
}

.header {
  color: black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #fff;
}

.header-button-two {
  padding: 13px 25px !important;
  color: #fff !important;
  transition: all 150ms ease-in-out;
  border: 1px solid #f05742 !important;
  background-color: #f05742 !important;
  font-weight: 550;
  margin-left: 10px;
}
.header-button-one {
  padding: 13px 25px !important;
  color: #343e61 !important;
  background-color: transparent !important;
  border: 1px solid #343e61 !important;
  transition: all 150ms ease-in-out;
  font-weight: 550;
}

.home-banner-content-container-button,
.innerpage-banner-content-container-button {
  display: flex;
  gap: 10px;
}

.headerContainer {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}

.headerLogo {
  contain-intrinsic-size: 176px 44px;
}

.headerButton {
  display: none;
}

.link-white {
  color: #ffffff;
  text-decoration: underline;
}

@media screen and (max-width: 1000px) {
  .desktopMenu,
  .header-top-container {
    display: none;
  }

  .burgerMenu {
    display: block;
  }

  .headerLogo {
    padding: 10px 0px;
    width: 5rem;
    height: auto;
    object-fit: contain;
  }
}

@media screen and (max-width: 1280px) {
  .headerLogo {
    display: inline-block;
    min-width: 100px;
    height: auto;
    object-fit: contain;

    padding: 8px; /* Add some space */
  }

  .header-top-container {
    justify-content: center;
  }
  .header-top-right {
    width: auto;
    gap: 50px;
  }
  .headerContainer {
    width: auto;
  }
}
