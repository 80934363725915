@import "/src/styles/shared/common";
.contact-support-list-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}
.contact-support-list-card {
  padding: 2rem 1.5rem;
  border-radius: $border-radius;
  border: 1px solid $border;
}
.contact-support-list-card-title {
  @extend .paragraphTitle;
  padding: 0 !important;
}

.contact-support-list-card-link {
  display: flex;
}
.contact-support-list-card-link svg {
  margin: 5px 0 0 5px;
  color: #1677ff;
}
.contact-support-list-card:hover {
  transition-duration: 0.3s;
  box-shadow: 0px 30px 120px 0px rgba(52, 62, 97, 0.1019607843);
}

@media screen and (max-width: 768px) {
  .contact-support-list-container {
    display: grid;
    grid-template-columns: 1fr;
  }
}
