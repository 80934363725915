@import "../../styles/shared/common";
.promo-video-container {
  // background-image: url(../../../public/images/general/wave.svg);
  background-size: cover;
  width: 70%;
  margin: auto;
  padding: 3rem 0;
}

.promo-video {
  display: block;
  margin: 2em auto;
  width: 100%;
  border-radius: 30px;
  box-shadow: 0 30px 120px 0 rgba(52, 62, 97, 0.102);
}

@media only screen and (max-width: 768px) {
  .promo-video {
    display: block;
    margin: 1em auto;
    width: 100%;
    height: auto;
    border-radius: 30px;
    box-shadow: 0 30px 120px 0 rgba(52, 62, 97, 0.102);
  }
  .promo-video-container {
    padding: 1rem 0;
  }
}
