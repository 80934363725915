@import "/src/styles/shared/common";
.integration-card-wrapper {
  margin: 3rem auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}
.integration-section-header {
  width: 75%;
  margin: auto;
  text-align: center;
  & p {
    margin: 0.5rem 0 !important;
  }
}
.integration-card {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
  background-color: $foreground;
  border: 1px solid $border;
  border-radius: 15px;
  padding: 1rem 1.5rem;
  transition: transform 0.5s;
  &:hover {
    box-shadow: $box-shadow;
    transform: scale(1.05);
  }
  &-icon img {
    width: 50px;
  }
  &-content {
    & h3 {
      padding: 0 !important;
    }
  }
}

@media screen and (max-width: 1280px) {
  .integration-card-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .integration-card-wrapper {
    grid-template-columns: 1fr;
  }
  .integration-section-header {
    width: 100%;
  }
  .integration-card {
    gap: 20px;
  }
}
