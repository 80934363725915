////////////// Colors
$font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial, sans-serif;
$brand-color: #f05742;
$orange: #ff6854;
$light-grey: #5e626e;
$light-red: #ffeeec;
$blue: #343e61;
$light-blue: #465382;
$highContractText: #d60000;
$content: #5e626e;
$secondary-blue: #bac1da;
$icon-bg: #f2f2f2;
$bg-color: #222e42;
$background: #efeff1;
$foreground: #fbfbfb;
$border: #dddee2;
$white: #ffffff;
$black: #000000;
$box-shadow: 0px 30px 120px 0px rgba(52, 62, 97, 0.1019607843);
$border-radius: 20px;
$border-radius-sm: 10px;
$border-radius-lg: 30px;
////////////// Device Size

.outerContainer {
  padding: 3rem 0;
  width: 100%;
}

.href-text {
  color: #1677ff;
}

.credit-card-text {
  color: $highContractText; /* Darker Red */
  font-size: 14px;
}

.blue {
  background-color: $light-blue;
}

.italics {
  font-style: italic;
}

.innerContainer {
  width: 90%;
  max-width: 109.3rem;
  margin: auto;
  // text-align: center;
}

.left {
  text-align: left !important;
}
.center {
  text-align: center;
}
.margin {
  margin: 15px 0 !important;
}
.images {
  width: 100%;
}
.video {
  width: 100%;
  height: 420px;
}

h1,
h3 {
  margin: 0;
}

ul {
  margin: 0;
}

.overflow-x {
  overflow-x: auto;
}

.bannerTitle {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  color: $blue;
}

.sectionTitle {
  //margin: 0;
  font-size: 30px;
  line-height: 2.5rem;
  font-weight: 700;
  margin: auto;
  text-align: center;
  color: $blue;
}

.sectionMainTitle {
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 700;
  color: $brand-color;
}

.paragraphTitle {
  font-size: 17px;
  line-height: 25px;
  font-weight: 700;
  margin: 0 !important;
  color: $blue;
}
.para {
  font-size: 17px;
  line-height: 25px;
  font-weight: 400;
  padding: 0.3rem 0 0 0 !important;
  color: $content;
}
.noPadding {
  padding-top: 0 !important;
}
.highlightBorder {
  border: 2px solid #f05742;
  width: 75px;
}

.banner-float-left {
  float: none;
}

.fa-chevron-circle-right {
  margin-left: 10px;
}

.integration__logoContainer img {
  width: 90%;
}
ul.noDots {
  list-style-type: none;
}

.bar {
  display: block;
  height: 3px;
  width: 100px;
  background: $brand-color;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin: 2rem;
}

.bar:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: #fff;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}

@keyframes MOVE-BG {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(85px);
    transform: translateX(85px);
  }
}
.divider-bar-container {
  padding: 10px;
  width: 60%;
  text-align: Center;
  margin: auto;
  color: var(--white);
}

.divider-bar-container .bar {
  margin: auto;
}

.ant-float-btn-body {
  background-color: #ef7174 !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-float-btn-default
  .ant-float-btn-body
  .ant-float-btn-content
  .ant-float-btn-icon {
  color: rgb(255 255 255 / 88%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/// overflow ///
.overflow-x-auto {
  overflow-x: auto;
}

///// table ///
td,
th {
  border: 1px solid $border;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f240;
}
@media only screen and (max-width: 1280px) {
  .bannerTitle {
    font-size: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .outerContainer {
    width: 100%;
    padding: 3rem 0;
  }

  .innerContainer {
    width: 90%;
  }
  .video {
    width: 100%;
    height: auto;
  }
  .credit-card-text {
    font-size: 12px;
  }
  .banner-float-left {
    float: left;
  }

  .bannerTitle {
    font-size: 30px !important;
    line-height: 2.5rem;
  }

  .sectionTitle {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  .paragraphTitle {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }

  .para {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.rc-menu-container a:hover {
  color: #000 !important;
}

input,
textarea {
  font-family: $font-family;
}
