/////////////////////////////////// partnerslogo styles////////////////////////////////////////////////////
@import "../../styles/shared/common";
.partners-logo-section {
  margin-top: 3rem;
}

$logo-max-width: 110px;
$logo-max-height: 60px;

.partners-logo-section-title {
  @extend .sectionTitle;
  margin-top: 4rem;
}

.logogrid {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  padding: 30px 0px;
  width: 90%;
  margin: auto;
  justify-content: center;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__img {
    object-fit: cover;
    max-width: $logo-max-width;
    max-height: $logo-max-height;
    // filter: grayscale(100%) contrast(25%) hue-rotate(0deg) sepia(0)
    //   saturate(100%) brightness(100%) invert(0) drop-shadow(0px 0px 0px #343e61);
    // transition: filter 0.3s ease;

    // &:hover {
    //   filter: grayscale(0) contrast(100%);
    // }
  }
}

/////////////////////////////////// partners features styles////////////////////////////////////////////////////

.partners-features-container {
  background: $foreground;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  // border-top: 1px solid $border;
  // border-bottom: 1px solid $border;
}
.partners-features-header {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.partners-features-header-image img {
  width: 100%;
  height: 800px;
  object-fit: cover;
  display: block;
}

.partners-features-card-title {
  display: flex;
  align-items: center;
  @extend .paragraphTitle;
  font-weight: 700;
  text-align: start;
}

.partners-features-card-details {
  width: 45%;
  padding: 0 2rem;
}
.partners-features-card-details-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 1rem;
}

.testimonial-section {
  display: flex;
  margin: 3rem auto;
  gap: 50px;
  /////////////////////////////////// partners testimonial styles////////////////////////////////////////////////////
  .testimonial-section-cards {
    display: flex;
    gap: 30px;

    .testimonial-card {
      display: flex;
      padding: 2rem;
      gap: 2rem;
      border: 1px solid $border;
      border-radius: 20px;
      text-align: center;
      background-color: $foreground;
      &:hover {
        box-shadow: $box-shadow;
      }
      &-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
      }
      &-logo img {
        width: 80px;
      }

      &-name {
        @extend .paragraphTitle;
        padding: 0 !important;
      }
    }
  }
}
///////////////////////////////////////partners type/////////////////////////////////////

.partners-type-card-container {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.partners-type-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: $foreground;
  width: 400px;
  padding: 30px;
  border: 1px solid $border;
  border-radius: 20px;
  &:hover {
    box-shadow: $box-shadow;
  }
  & svg {
    width: 45px;
    height: 45px;
    background-color: $icon-bg;
    border-radius: 10px;
    padding: 0.7rem;
    color: #f05742;
  }
}
.partners-type-card-title {
  @extend .paragraphTitle;
  padding: 0 !important;
}

.partners-type-card-description {
  @extend .para;
}

///////////////////////////////////////////partners form///////////////////////////////////////////
:root {
  --box-height: 60px;
  --border-radius: 5px;
  --space-between: 20px;
  --font-size: 16px;

  --color-0: #ffffff;
  --color-1: #ebebeb;
  --color-2: #c5c3c6;
  --color-3: #212529;
  --color-4: #659b5e;
  --color-5: #ce4257;
}

.partner-form-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
}
.partner-form-main-container {
  display: flex;
  gap: 30px;
  // background-color: rgba(228, 235, 244, 0.715);
  background: $background;
  padding: 3rem;
  background-image: url("../../../public/images/general/bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}
.partner-form-content-container {
  display: flex;
  align-items: flex-end;
  width: 40%;
}

.partner-form-input-container {
  width: 60%;
  background: $foreground;
  padding: 30px;
  border-radius: 20px;
}

.partner-form-input-container .row-input {
  border-bottom: 1px solid $border !important;
}
.partners-form-content-container-text {
  font-size: 50px;
}
#partners-form-container {
  padding: 40px;
  // background: $background;
  border-radius: calc(3 * var(--border-radius));
  &-title {
    @extend .sectionTitle;
    margin-bottom: 20px;
  }
}
.partners-form-container-title {
  @extend .sectionTitle;
  text-align: start;
}
#partners-form {
  opacity: 98%;
  border: none;
  border-radius: calc(2 * var(--border-radius));
}
.partners-form-row-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 25px;
}

::placeholder,
option {
  @extend .para;
  font-size: 13px;
}
select#category {
  padding: 20px;
  width: 100%;
  border: 1px solid #d8dae1 !important;
  border-radius: 5px;
}

// .row-label,
// .row-input {
//   padding: 5px 0;
//   margin: 0;
//   width: 100%;
//   height: var(--box-height);
//   display: block;
//   &:focus {
//     outline: none;
//     box-shadow: 0 0 5px #374164;
//   }
//   &::placeholder {
//     @extend .para;
//     color: #999eb0;
//     font-size: 15px;
//   }

//   &[type="text"] {
//     width: 100%;
//     padding: 12px 20px;
//     box-sizing: border-box;
//   }
//   &[type="email"] {
//     width: 100%;
//     padding: 12px 20px;
//     box-sizing: border-box;
//   }
//   &[type="number"] {
//     width: 100%;
//     padding: 12px 20px;

//     box-sizing: border-box;
//   }
// }

// .row-label {
//   @extend .paragraphTitle;
// }

// .row-label:not(:first-child) {
//   margin-top: var(--space-between);
// }

// .row-input:not(.small) {
//   background: white;
//   border: none;
//   border-radius: var(--border-radius);
// }

.partners-form-upload input {
  background-color: white;
  padding: 13px;
  border-radius: 5px;
  width: 94%;
}
.form-close-button {
  position: absolute;
  top: 3%;
  right: 3%;
  background-color: #ffffff;
  border: none;
  font-size: 30px;
  color: $brand-color;
  cursor: pointer;
}

.small {
  height: calc(var(--box-height) * 0.75);
}

#comments {
  padding: 10px;
  margin-top: 20px;
  height: 120px;
  width: 98%;
  background: white;
  border: none;
  border-radius: var(--border-radius);
  resize: none;
}

#partners-form-submit {
  min-width: 20%;
  margin-top: 20px;
  background-color: $brand-color;
}

@media screen and (max-width: 768px) {
  .partner-form-main-container {
    flex-direction: column;
    padding: 20px;
    background-size: cover;
  }
  .partner-form-input-container {
    width: 100%;
    padding: 0;
  }
  .partners-form-row-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 20px;
  }
  #partners-form-submit {
    width: auto;
  }
  #partners-form-container {
    width: auto;
  }
  .partner-form-content-container {
    width: 100%;
  }
}

//////////////////////////////////////////responsive//////////////////////////////////////////////

@media only screen and (max-width: 768px) {
  .partners-features-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 4rem;
  }

  .partners-features-header {
    text-align: center;
    width: 100%;
    flex-direction: column-reverse;
  }
  .partners-features-header-title {
    text-align: center;
    width: 100%;
  }

  .partners-features-header-image img {
    height: 400px;
  }
  .partners-features-card-details {
    grid-template-columns: 1fr;
    gap: 30px;
    color: $blue;
    width: 90%;
    padding: 0;
  }

  .partners-type-card-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .partners-type-card {
    width: auto;
  }

  .testimonial-section {
    flex-direction: column;
  }

  .testimonial-section-cards {
    width: 100% !important;
    flex-direction: column;
  }

  .partners-testimonial-card-button {
    visibility: hidden;
  }
  .testimonial-partners-content {
    height: 500px;
    overflow-y: scroll;
  }
  .partners-type-card {
    gap: 0px;
  }
  .partner-form-container {
    display: flex;
    flex-direction: column;
  }
  .partner-form-button {
    width: auto;
  }
}
