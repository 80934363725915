.navigation {
  $bem-block: &;

  &-item {
    display: inline-block;
    margin: 0 9px;
    position: relative;
    transition: 0.2s ease-in-out;

    &:hover {
      &::before {
        opacity: 1;
        transform: translateX(2px);
      }
    }

    &:hover #{$bem-block}-link {
      color: #343e61;
    }
  }

  &-link {
    display: block;
    padding: 10px 13px;
    text-decoration: none;
    color: #343e61;
    font-size: 1rem;
    font-weight: 550;
    cursor: pointer;
    position: relative;
    &:hover:after {
      width: 50%;
    }
  }

  &-link:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 0;
    bottom: -0.1rem;
    left: 25px;
    transition: 300ms ease-in-out;
    background: rgb(240, 87, 67);
    background: linear-gradient(
      270deg,
      rgba(240, 87, 67, 1) 0%,
      rgba(1, 250, 255, 0) 100%,
      rgba(0, 212, 255, 0) 100%
    );
    border-radius: 50px;
  }
}

.navigation-item-parent-nav-link {
  text-decoration: none;
  color: #343e61;
}

.submenu {
  $bem-block: &;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50px;
  width: 750px;
  background: #fff;
  border-radius: 20px;
  border: 1px #f1f1f1 solid;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  transition: 0.5s;
  transform: translateY(50px);
  z-index: 0;
  border-bottom: #f05742 3px solid;

  &-content {
    display: flex;
  }
}
.submenu-box-title {
  margin-top: 2rem !important;
  margin-left: 3rem !important;
  padding: 0 !important;
  font-size: 17px;
  line-height: 25px;
  font-weight: 700;
  color: #343e61;
}
.resource-submenu {
  left: -320px;
}
.solutions-submenu {
  left: -280px;
  // width: 900px;
}
.platform-submenu {
  left: -225px;
}

.partners-submenu {
  right: -200px;
}
.company-submenu {
  right: -220px;
}
.submenu-navigation {
  $bem-block: &;
  padding: 20px;
  list-style-type: none;

  &-item {
    &:not(:first-of-type) {
      margin-top: 5px;
    }
  }

  &-link {
    text-decoration: none;
    display: block;
    padding: 10px 20px;
    position: relative;
    display: flex;
    gap: 15px;
    &-icon {
      padding: 0.7rem;
      border-radius: 50%;
      background-color: #f4f4f4;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & svg {
      color: #f05742;
    }
    &:hover {
      background: #f2f2f2;
      border-radius: 10px;
    }

    &:hover #{$bem-block}-icon {
      transform: translateY(-50%) translateX(4px);
    }
  }

  &-title,
  &-subtitle {
    display: block;
  }

  &-title {
    color: #343e61;
    font-size: 1rem;
    font-weight: 500;
  }

  &-subtitle {
    color: #515667;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.2px;
  }
  &-solution-title {
    font-weight: 600;
    padding: 15px 0px 0px 55px;
    font-size: 1.1rem;
    color: #343e61;
  }
}

.navigation-item:hover {
  .submenu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    z-index: 1;
  }
}

.generative-ai-nav {
  margin: 10px 27px;
  width: 100%;
  & a {
    color: #343e61;
    text-decoration: none;
    font-weight: 600;
    font-size: 20px;
  }
}

@media screen and (max-width: 1280px) {
  .navigation-item {
    margin: 0;
  }
  .navigation-link {
    padding: 5px 10px;
    font-size: 15px;
  }
}
