@import "/src/styles/shared/common";
.company-banner-outer-container {
  background-color: #ffffff;
}
.company-banner-inner-container {
  position: relative;
  isolation: isolate;
}
.company-banner-content-wrapper-outer {
  background-color: rgba(0, 0, 0, 0.7);
}
.company-banner-content-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 50rem;
  padding: 12rem 0;
}
#company-banner-background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.company-banner-content {
  text-align: center;
}
.company-banner-content-title {
  font-size: 3.75rem;
  font-weight: bold;
  line-height: 1;
  color: #ffffff;
  // color: $blue;
}
.company-banner-content-text {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #ffffff;
}

.company-banner-cta-buttons {
  margin-top: 2.5rem; /* 10 units * base spacing unit */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem; /* 6 units * base spacing unit */
}
.company-banner-cta-button-1 {
  // border-radius: 0.375rem;
  border-radius: 100px;
  background-color: $brand-color !important;
  // padding-left: 0.875rem;
  // padding-right: 0.875rem;
  // padding-top: 0.625rem;
  // padding-bottom: 0.625rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(66, 153, 225, 0.05);
}
.company-banner-content-privacy-policy-section {
  & p {
    margin-top: 1.5rem !important;
    line-height: 1.5;
    color: #ffffff;
  }
}
.company-banner-content-privacy-policy-section a {
  color: #ffffff;
  text-decoration: underline;
}

.company-banner-cta-button {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  & p {
    color: #ffffff98;
  }
}
.company-banner-cta-button-1:hover {
  background-color: rgb(255 255 255 / 40%) !important;
}

.company-banner-cta-button-1:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.company-banner-cta-button-1:focus-visible {
  outline: 2px solid #4f46e5;
}
@media screen and (max-width: 768px) {
  .company-banner-content-title {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}
@media screen and (max-width: 1024px) {
  .company-banner-content-wrapper {
    padding: 4rem 1rem;
  }
}
