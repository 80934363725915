@import "/src/styles/shared/common";
.about-statement-container {
  display: flex;
  justify-content: space-around;
}
.about-statement-container-title {
  @extend .sectionTitle;
  width: 70%;
  text-align: start;
  margin: 0;
}
.about-statement-container-description {
  @extend .para;
  width: 100%;
}
@media screen and (max-width: 1280px) {
  .about-statement-container {
    flex-direction: column;
  }
}

