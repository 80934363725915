@import "/src/styles/shared/common";
.generative-ai-piller-outer-container {
  background-color: #ffffff;
}
.generative-ai-piller-inner-container {
  width: 90%;
}
.generative-ai-piller-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 3rem;
}
.generative-ai-piller-image {
  width: 300px;
  height: 100px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: $brand-color;
  font-size: 30px;
}
.generative-ai-piller {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: #f7f7f7;
  gap: 30px;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .generative-ai-piller-container {
    grid-template-columns: 1fr;
  }
  .generative-ai-piller {
    flex-direction: column;
    gap: 0;
  }
  .generative-ai-piller-image {
    width: 100%;
    height: 50px;
  }
}

///////////////////////////inextlabs generative-ai solutions section///////////////////////////

.generative-ai-solution-outer-container {
  // background-color: $background;
  background-image: url("../../../public/images/generative-ai/light-bulb-with-white-splash-mixed-media.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 3rem 0;
}

.generative-ai-solution-icon {
  width: 50px;
  height: 50px;
  background-color: $icon-bg;
  border-radius: 10px;
  padding: 0.7rem;
  color: #f05742;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generative-ai-solutions-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 3rem;
}
.generative-ai-solution-body-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin: 3rem 0;
}
.generative-ai-solution-card {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  padding: 20px;
  border-radius: 20px;
  background-color: $foreground;
  box-shadow: $box-shadow;
  &-title h3 {
    padding-top: 5px !important;
  }
  &-text p {
    // margin: 0 !important;
  }
}

@media screen and (max-width: 1280px) {
  .generative-ai-solution-body-container {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  }
}
@media screen and (max-width: 768px) {
  .generative-ai-solution-body-container {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
