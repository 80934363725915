@import "/src/styles/shared/common";
.contact-details-container {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  margin-top: 3rem;
  &-info-content {
    width: 80%;
    &-title {
      @extend .sectionTitle;
      text-align: start;
    }
    &-description {
      @extend .para;
    }
  }
  &-form-container {
    width: 100%;
  }
}
.contact-details-container-info-content-card-container {
  margin-top: 2rem;
  display: grid;
  gap: 30px;
}
.contact-card {
  padding: 3rem 1.5rem;
  border-radius: 20px;
  &-contentOne {
    @extend .paragraphTitle;
    color: #fff;
    padding: 0 !important;
  }
  &-contentTwo {
    @extend .sectionTitle;
    text-align: start;
    color: #fff;
  }
}
.contact-details-container-info-content-card-container
  .contact-details-container-info-content-card {
  background-color: #2aa9c9;
  border-radius: $border-radius;
}

.contact-details-container-info-content-contacts-card {
  background-color: $brand-color;
  border-radius: $border-radius;
}

.contact-details-container-form-container-title {
  @extend .sectionTitle;
  text-align: start;
}

@media screen and (max-width: 768px) {
  .contact-details-container {
    grid-template-columns: 1fr;
  }
}
