@import "/src/styles/shared/common";
@import "/src/styles/shared/button";
.value-proposition-wrapper {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 3rem 0;
}
.value-proposition-content-wrapper {
  display: grid;
  align-items: center;
  justify-content: center;
  gap: 30px;
  align-items: stretch;
}

.value-proposition-inner-container {
  background-color: $background;
  width: 90% !important;
  border-radius: 30px;
}
.value-proposition-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start;
  flex: 1; // Allows the content to grow and shrink as needed
  //   box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  &-title {
    @extend .paragraphTitle;
    padding: 0 !important;
  }
  &-description p {
    @extend .para;
    padding: 0 !important;
  }
  &-icon {
    padding: 0.7rem;
    background-color: $icon-bg;
    width: fit-content;
    border-radius: 10px;
    color: $brand-color;
    display: flex;
    & img {
      width: 40px;
    }
  }
  & ol {
    list-style-type: circle;
  }
}

.show-more-button {
  @extend .button;
  padding: 0.5rem 1.5rem !important;
  border-radius: 10px;
  background-color: transparent !important;
  color: $blue !important;
  border: 1px solid $blue !important;
}
@media screen and (max-width: 1280px) {
  .value-proposition-content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }
}

@media screen and (max-width: 768px) {
  .value-proposition-content-wrapper {
    display: grid;
    grid-template-columns: 1fr !important;
    padding: 0;
  }
}
