@import "/src/styles/shared/common";
.assistant-solutions-card {
  width: 33.33%;
  transition: transform 0.5s ease-in-out;
  background-color: #fff;
  box-shadow: $box-shadow;
  padding: 1rem;
  border-radius: 15px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  position: relative;
  z-index: 1;
  &-wrapper {
    display: flex;
    gap: 40px;
    margin-top: 30px;
  }
  &-image {
    width: 100%;
    & img {
      width: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
  }
  &-button {
    & button {
      color: $blue;
      font-weight: 500;
      display: flex;
      align-items: center;
      background: none;
      // padding: 5px 10px;
      // border: 1px solid $border;
      // border-radius: 5px;\
      
      gap: 2px;
      &:hover {
        svg {
          //// rotate the svg icon on hover ////
          transform: rotate(45deg); ////
          transition: transform 0.2s ease-in-out; ///
        }
      }
    }
    margin-top: 8px;
  }
  &:hover {
    transform: scale(0.98);
  }
}

///// responsive design /////
///

@media screen and (max-width: 1280px) {
  .assistant-solutions-card {
    width: auto;
    &-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
}
@media screen and (max-width: 768px) {
  .assistant-solutions-card {
    width: auto;
    &-wrapper {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
}
