@import "/src/styles/shared/common";
.webinar-modal-container {
  min-width: 900px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  z-index: 1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 1000; /* Set a z-index lower than your modal to ensure it's below the modal */
  display: flex;
  align-items: center;
  justify-content: center;
}

.webinar-modal-close {
  position: fixed;
  top: 0px;
  right: 10px;
  font-size: 30px;
  color: #f05742;
  cursor: pointer;
  z-index: 1001;
}

.webinar-modal-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
}
.webinar-modal-content-wrapper {
  display: flex;
  align-items: center;
}
.webinar-modal-image-content-main-title {
  @extend .para;
  color: #ffffff;
  margin: 0 20px !important;
  background-color: #4e3443;
  padding: 2px 10px !important;
  font-size: 13px;
  border-radius: 5px;
  width: fit-content;
}
.webinar-modal-content-text {
  @extend .para;
}
.webinar-modal-image-content-date {
  @extend .para;
  display: flex;
  align-items: center;
  gap: 5px;
  // color: #4e3443;
  font-weight: bold;
  margin: 0 20px !important;
}

.webinar-modal-countdown-item-number {
  @extend .para;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}

.webinar-modal-image-content-wrapper {
  background-image: url("../../../public/images/modal-background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  color: #ffffff;
  border-radius: 15px;
  padding: 2rem;
}
.webinar-modal-image-container {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  & img {
    max-width: 90% !important;
    border-radius: 20px;
    margin: 0 1.5rem;
  }
}
.webinar-modal-image-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  color: #2e2e2e;
  &-logo {
    margin: 0 20px;
  }
}

.webinar-modal-image-content-title {
  @extend .sectionTitle;
  margin: 0 20px;
  text-align: start;
}
.webinar-modal-image-content-text {
  @extend .para;
  margin: 0 20px !important;
  padding: 0 !important;
}

.webinar-modal-content-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background-color: transparent;
}

.webinar-modal-content-button {
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  border-radius: 5px;
  background-color: $blue;
  color: #ffffff;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: darken($blue, 10%);
  }
}

/* Add a media query for smaller screens */
@media only screen and (max-width: 1080px) {
  .webinar-modal-container {
    min-width: 90%; /* Adjust as needed for smaller screens */
  }
}

/* Adjust the image height for smaller screens */
@media only screen and (max-width: 768px) {
  .webinar-modal-container {
    min-width: 85%; /* Adjust as needed for smaller screens */
  }

  .webinar-modal-content-wrapper {
    flex-direction: column-reverse;
    gap: 2rem;
  }
  .webinar-modal-image-content-text {
    width: fit-content;
  }
  .webinar-modal-image-content-date {
    color: $blue;
  }
  .webinar-modal-content-footer {
    flex-direction: column;
  }
}
