@import "/src/styles/shared/common";
.webinar-footer-button {
  background: #f05742;
}
.resources-webinar {
  &-item-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    align-items: stretch;
  }
  &-card {
    width: 100%;
    border: 1px solid $border;
    border-radius: 15px;
    padding: 1.5rem;
    box-shadow: $box-shadow;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-type {
        background-color: #e9e9f5;
        padding: 0.5em 2em;
        font-size: 0.8rem;
        border-radius: 10px;
        color: $blue;
      }
      &-date-time {
        background-color: #fee5e2;
        padding: 0.5em 2em;
        font-size: 0.8rem;
        border-radius: 10px;
        color:$brand-color;
      }
    }
    &-image {
      width: 100%;
      object-fit: cover;
      border-radius: 15px;
      & img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
    }
    &-tagline {
      margin: 1rem 0 !important;
    }
    &-date-time {
      margin: 1rem 0;
      color: $blue;
      font-size: 1rem;
      font-weight: 500;
    }
    &-button {
      color: $brand-color;
      font-size: 1rem;
      font-weight: 500;
    }
  }
}

.resources-webinar-card-image {
  height: auto !important;
}
.resources-webinar-card-title {
  color: $blue !important;
}
