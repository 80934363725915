.button {
  background-color: #f05742 !important;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1rem !important;
  text-transform: none !important;
  letter-spacing: 0.02em;
  padding: 12px 30px !important;
  border-radius: 100px !important;
  transition: all 0.5s ease !important;
  transform: translate(0);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  cursor: pointer;
}
.button:hover::before {
  transform: scaleY(1.1) skewX(35deg);
}

.bannerSecondaryButton {
  background-color: #fff !important;
  border: 1px solid $blue !important;
  color: $blue !important;
  text-shadow: none;
}

.custom-loader {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #36b807;
  -webkit-mask: radial-gradient(
    circle closest-side at 50% 40%,
    #0000 94%,
    #000
  );
  transform-origin: 50% 40%;
  animation: s5 1s infinite linear;
}

@keyframes s5 {
  100% {
    transform: rotate(1turn);
  }
}

@media screen and (max-width: 1280px) {
  .button {
    white-space: nowrap;
  }
  .header-button {
    padding: 10px 15px;
    white-space: nowrap;
    font-size: 15px !important;
  }
}
@media screen and (max-width: 768px) {
  .button {
    padding: 18px 25px !important;
  }
}
@media screen and (max-width: 480px) {
  .button {
    padding: 10px 15px !important;
    font-size: 10px;
  }
  .button img {
    width: 8px;
  }
  .bannerSecondaryButton {
    gap: 5px;
  }
}
