@import "/src/styles/shared/common";
.buisness-statement-container {
  // background-image: linear-gradient(to left, #dddee4, #e6e6eb, #eeeef1, #f7f7f8, #ffffff);
  // background-color: $bg-color;
  // width: 90%;
  margin: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  padding: 0 4rem;
  // background-color: $background;
  background-image: url("https://cdn.inextlabs.ai/images/general/beams-basic.webp");
  box-shadow: $box-shadow;
  background-position: top;
  border-radius: 20px;
  border: 1px solid $border;
  display: flex;
  align-items: center;
  overflow: hidden;
  & img {
    width: 100%;
  }
}

.buisness-statement-svg {
  position: absolute;
  z-index: -1;
}

.buisness-statement-main-title {
  font-size: 25px;
  font-weight: bold;
  width: 77%;
  color: $blue;
  text-align: left;

  // backdrop-filter: brightness(35%);
}

/// responsive design

@media (max-width: 768px) {
  .buisness-statement-container {
    width: auto;
    padding: 0 1rem;
    flex-direction: column;
    background-size: cover;
    background-position: center;
  }
  .buisness-statement-main-title {
    font-size: 20px;
    width: 100%;
    margin: 1rem 0;
  }
  .buisness-statement-svg {
    display: none;
  }
}
