@import "/src/styles/shared/common";
.resources-banner-outer-container {
  background-color: $background;
  // background: url("./beams-basic.png");
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
}
.resources-banner-inner-container {
  background-color: $foreground;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 700px;
  margin: 2rem 3rem;
  border: 1px solid $border;
  border-radius: 15px;
  .resources-banner-content-container {
    width: 50%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h1 {
      font-size: 3.75rem;
      font-weight: bold;
      line-height: 1;
    }
    a {
      width: fit-content;
      text-decoration: none;
    }
    button {
      width: fit-content;
      background-color: $blue;
    }
  }
  .resources-banner-content-button {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .resources-banner-image-container {
    width: 50%;
    img {
      width: 100%;
      height: 700px;
      object-fit: cover;
      display: block;
      border-radius: 0 15px 15px 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .resources-banner-inner-container {
    flex-direction: column;
    height: auto;
    padding: 1rem;
    margin: auto;
    border-radius: 0;
    border: none;
    .resources-banner-content-container {
      width: 100%;
      padding: 1rem;
      h1 {
        font-size: 2.5rem;
      }
    }
    .resources-banner-image-container {
      width: 100%;
      img {
        height: 400px;
        border-radius: 15px 15px 0 0;
      }
    }
  }
}
