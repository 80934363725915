@import "../../styles/shared/common";
.valuePropItemsTextInnerContainerSectionTitle {
  @extend .sectionMainTitle;
  margin: 0.2rem 0.1rem;
  color: $brand-color;
}

.value-props-main-description {
  @extend .para;
  text-align: center;
}

.inner-value-props-main-container {
  margin-top: 3rem;
}
.valuePropItemsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  padding: 2rem 0;
}

.valuePropItemsTextOuterContainer {
  display: grid;
  width: 100%;
}

.valueprops-description {
  display: flex;
  margin-top: 15px;
  gap: 10px;
}

.valueprops-bychannel-description {
  & h4 {
    font-weight: bold;
    color: $blue;
    margin: 5px 0;
  }
}
.valueprops-bychannel-sub-description {
  margin-left: 25px;
}

.valuePropItemsTextInnerContainer {
  margin: auto;
  text-align: left;
}
.valuePropItemsTextInnerSubContainer {
  margin-left: 2rem;
}
.valuePropItemsTextInnerContainerTitle {
  @extend .sectionTitle;
}

.valuePropItemsTextInnerContainerSubTitle {
  color: $blue;
  @extend .paragraphTitle;
  display: flex;
}

.valuePropItemsTextInnerContainerParagraph {
  @extend .para;
}

.valuePropItemsTextInnerContainerSubParagraph {
  @extend .para;
}

.valuePropItemsImageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.valuePropItemsImageContainerImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 20px 20px;
}

.valuePropItemsContainerReverseDirection {
  flex-direction: row-reverse;
  border-radius: 20px;
}
.valuePropItemsContainerReverseDirection .valuePropItemsImageContainerImage {
  border-radius: 20px 20px;
}

// .inner-value-props-main-container {
//   padding: 5rem 0;
//   // background-color: $background;
// }

@media screen and (max-width: 1280px) {
  .valuePropItemsTextOuterContainer {
    display: grid;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .valuePropItemsTextInnerSubContainer {
    margin-left: 0;
  }
  .valuePropItemsContainer {
    flex-direction: column;
  }
  .valuePropItemsImageContainer {
    width: 100%;
    height: 350px;
  }
  .valuePropItemsImageContainerImage {
    border-radius: 20px;
  }
  .valuePropItemsContainerReverseDirection .valuePropItemsImageContainerImage {
    border-radius: 20px;
  }
  .valuePropItemsTextInnerContainer {
    width: 100%;
  }
  .valuePropItemsTextOuterContainer {
    display: grid;
    width: 100%;
  }
}
