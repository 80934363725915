.burger-menu-container {
  position: fixed;
  font-size: 20px;
  right: 0;
  top: 15px;
  width: 100%;
}

.burger-menu-background {
  @extend .burger-menu-container;
  pointer-events: none;
  transition: 0.3s;
  right: 50px;
  top: 50px;
  transform: translate3d(50%, -50%, 0);
  transform-origin: center center;
  &.fs {
    transform: translate3d(50%, -50%, 0);
    width: 300vw;
    height: 300vw;
  }
}
.burger-menu-button {
  @extend .burger-menu-container;
  color: #343e61;
  position: absolute;
  right: 25px;
  cursor: pointer;
  transition: 0.4s;
  transform-origin: center;

  &.fs {
    transform: rotate(-180deg) translateY(10px);
  }
  width: fit-content;
}

.burger-menu-items-container {
  font-weight: 600;
  font-size: 1.2rem;
  color: #343e61;
  background-color: #fff;
  width: 100%;
  text-align: start;
  opacity: 0;
  transition: 0.4s;
  transform: translateY(-200%);
  pointer-events: none;
  margin-top: 70px;
  div {
    transition: 1s;
    display: none;
    margin-top: 0px;
  }
  &.fs {
    transform: translateY(0);
    pointer-events: auto;
    opacity: 1;
    div {
      display: block;
    }
  }
}
.ant-menu-title-content {
  font-size: 15px;
  color: #343e61;
}

.ant-menu-item {
  font-weight: normal;
  color: #515667;
}
// MobileNav.scss
.mobile-nav {
  position: relative;
}

.overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  // backdrop-filter: blur(4px);
  z-index: 40;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255);
  // backdrop-filter: blur(8px);
  z-index: 50;

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4rem;
  }
}

.brand {
  font-size: 1.25rem;
  font-weight: 700;
  background: linear-gradient(to right, #2563eb, #9333ea);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.menu-toggle {
  padding: 0.5rem;
  border-radius: 9999px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.nav-menu {
  position: fixed;
  top: 4rem;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255);
  // backdrop-filter: blur(8px);
  // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(-1rem);
  pointer-events: none;
  z-index: 40;

  &--open {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }

  &__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }

  &__items {
    display: grid;
    gap: 0.5rem;
  }
}

.nav-item-wrapper {
  display: flex;
  flex-direction: column;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  border-radius: 0.5rem;
  color: #374151;
  font-weight: 500;
  transition: background-color 0.2s ease;
  text-decoration: none;

  &__content {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  svg {
    color: #6b7280;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &--with-subnav {
    cursor: pointer;
  }

  &--active {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &__chevron {
    transition: transform 0.3s ease;

    &--open {
      transform: rotate(-180deg);
    }
  }
}

.subnav {
  display: grid;
  gap: 0.25rem;
  padding-left: 2.75rem;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: 0;

  &--open {
    max-height: 300px;
    overflow-y: scroll;
    opacity: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.subnav-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.375rem;
  color: #4b5563;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.2s ease;

  svg {
    color: #6b7280;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
