@import "/src/styles/shared/common";
.hero-section-latest-updates {
  &-container {
    width: fit-content;
    margin: auto;
    padding: 0 4rem;
  }
  &-upper {
    display: flex;
    gap: 2rem;
    width: fit-content;
    align-items: center;
    padding: 0.6rem 2rem;
    color: white;
    border-radius: 0 0 20px 20px;
    backdrop-filter: blur(10px);
    background-position: top left;
    -webkit-backdrop-filter: blur(10px);
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    border: 1px solid $border;
    border-top: none;
  }
  &-title {
    font-weight: 600;
    // background-image: linear-gradient(to bottom, #4c2b5d, #692e75, #8b2d8b, #b0249d, #d800ab);
    // -webkit-background-clip: text;
    // background-clip: text;
    // -webkit-text-fill-color: transparent;
    color: #4c2b5d;
  }
  &-lower {
    background-color: #4c2b5d;
    color: white;
    padding: 1rem;
  }
}
