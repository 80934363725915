@import "/src/styles/shared/common";
:root {
  --box-height: 60px;
  --space-between: 20px;
  --font-size: 16px;

  --color-0: #ffffff;
  --color-1: #dcdcdd;
  --color-2: #c5c3c6;
  --color-3: #212529;
  --color-4: #659b5e;
  --color-5: #ce4257;
}

#career-form-container {
  width: 100%;
  border-radius: calc(3 * var(--border-radius));
  &-title {
    @extend .sectionTitle;
    margin-bottom: 20px;
  }
}

#career-form {
  width: 100%;
  padding: 30px;
  padding-top: 20px;
  background: $background;
  opacity: 98%;
  border: none;
  border-radius: calc(2 * var(--border-radius));
}
.career-form-row-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

::placeholder,
option {
  @extend .para;
  font-size: 13px;
}

.row-label,
.row-input {
  padding: 5px 0;
  margin: 0;
  height: var(--box-height);
  width: 100%;
  display: block;
  border: 1px solid $border;
  &:focus {
    outline: none; /* Removes the default outline */
    box-shadow: 0 0 5px #dbdee8;
  }

  &::placeholder {
    @extend .para;
    color: #999eb0;
    font-size: 15px;
  }

  &[type="text"] {
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
  }
  &[type="email"] {
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
  }
  &[type="number"] {
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
  }
}

.row-label {
  @extend .paragraphTitle;
}

.row-label:not(:first-child) {
  margin-top: var(--space-between);
}

.row-input:not(.small) {
  background: white;
  // border: none;
  border-radius: $border-radius-sm;
}

.career-form-upload input {
  background-color: white;
  padding: 13px;
  width: 100%;
  border: 1px solid $border;
  border-radius: $border-radius-sm;
}
.form-close-button {
  position: absolute;
  top: 3%;
  right: 3%;
  background-color: #ffffff;
  border: none;
  font-size: 30px;
  color: $brand-color;
  cursor: pointer;
}

.small {
  height: calc(var(--box-height) * 0.75);
}

#comments {
  padding: 10px;
  margin-top: 20px;
  height: 120px;
  width: 100%;
  border: 1px solid $border;
  background: white;
  border: none;
  border-radius: var(--border-radius);
  resize: none;
}

#career-form-submit {
  width: 20%;
  margin-top: 20px;
  background-color: $brand-color;
}

@media screen and (max-width: 768px) {
  .career-form-row-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 20px;
  }
  #career-form-submit {
    width: auto;
  }
}
