@import "/src/styles/shared/common";
@import "/src/styles/shared/button";
:root {
  --box-height: 60px;
  --border-radius: 5px;
  --space-between: 20px;
  --font-size: 16px;

  --color-0: #ffffff;
  --color-1: #dcdcdd;
  --color-2: #c5c3c6;
  --color-3: #212529;
  --color-4: #659b5e;
  --color-5: #ce4257;
}

#contact-form-container {
  width: 100%;
  max-width: 840px;
  border-radius: calc(3 * var(--border-radius));
  &-title {
    @extend .sectionTitle;
    margin-bottom: 20px;
  }
}

#contact-form {
  padding-top: 20px;
  // background: var(--color-1);
  opacity: 98%;
  border: none;
  border-radius: calc(2 * var(--border-radius));
}
.contact-form-row-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
}

// .row-label,
// .row-input {
//   padding: 5px;
//   margin: 0;
//   width: 100%;
//   height: var(--box-height);
//   display: block;
//   border: 1px solid #d8dae1 !important;
//   &:focus {
//     outline: none; /* Removes the default outline */

//     box-shadow: 0 0 5px #dbdee8; /* Adds a blue box shadow */
//   }
//   &::placeholder {
//     @extend .para;
//     color: #999eb0;
//     font-size: 15px;
//   }

//   &[type="text"] {
//     width: 100%;
//     padding: 12px 20px;
//     box-sizing: border-box;
//   }
//   &[type="email"] {
//     width: 100%;
//     padding: 12px 20px;
//     box-sizing: border-box;
//   }
//   &[type="number"] {
//     width: 100%;
//     padding: 12px 20px;
//     box-sizing: border-box;
//   }
// }

// .row-label {
//   @extend .paragraphTitle;
// }

// .row-label:not(:first-child) {
//   margin-top: var(--space-between);
// }

// .row-input:not(.small) {
//   background: white;
//   border: none;
//   border-radius: var(--border-radius);
// }

.contact-form-upload input {
  background-color: white;
  padding: 13px;
  border-radius: 5px;
  width: 94%;
}
.form-close-button {
  position: absolute;
  top: 3%;
  right: 3%;
  background-color: #ffffff;
  border: none;
  font-size: 30px;
  color: $brand-color;
  cursor: pointer;
}

.small {
  height: calc(var(--box-height) * 0.75);
}

#message {
  padding: 20px;
  margin-top: 20px;
  height: 120px;
  width: 100%;
  background: white;
  // border: none;
  border-radius: $border-radius-sm;
}

#contact-form-submit {
  @extend .bannerSecondaryButton;
  margin-top: 20px;
}

.copy-message {
  position: absolute;
  top: 25%;
  left: 92%;
  transform: translate(-50%, -50%);
  // background-color: #f8f8f8;
  padding: 5px 10px;
  border-radius: 5px;
  color: #ffffff;
}
.copy-icon {
  position: absolute;
  top: 15%;
  left: 92%;
  transform: translate(-50%, -50%);
  // background-color: #f8f8f8;
  padding: 5px 10px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 15px;
}
.error-message {
  color: red;
  font-size: 14px;
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .contact-form-row-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 20px;
  }
  #contact-form-submit {
    width: auto;
  }
  #message {
    width: 95%;
  }
}
