html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-display: swap; /* Ensures text is visible while font loads */
  margin: 0;
  padding: 0;
  font-display: swap;
}
.hidden {
  display: none;
}

@tailwind components;
@tailwind utilities;
@tailwind base;

@layer base {
  html {
    font-family: inherit;
  }
  p {
    margin: 0.6rem 0;
  }
}

// @layer base {
//   blockquote,
//   dl,
//   dd,
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6,
//   hr,
//   figure,
//   p,
//   pre {
//     margin: 0.6rem 0;
//   }
//   img {
//     max-width: none;
//   }
//   svg,
//   video,
//   canvas,
//   audio,
//   iframe,
//   embed,
//   object {
//     display: inline;
//     vertical-align: middle;
//   }
// }
// ol,
// ul,
// menu {
//   list-style: unset;
// }
