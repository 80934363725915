@import "/src/styles/shared/common";
.redirect-page {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    // background-color: $background;
    position: relative;
    border-radius: 30px;
    border: 1px solid $border;
    box-shadow: $box-shadow;
  }
  &-image {
    width: 70%;
    height: 100%;
    margin: 1rem;
    object-fit: contain;
    border-radius: inherit;

    & img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 2rem;
    & h2 {
      margin: 0 !important;
    }
    &-date-time {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: $blue;
      font-size: 1rem;
      font-weight: 500;
    }
    &-type {
      background-color: #e9e9f5;
      padding: 0.5em 2em;
      font-size: 0.8rem;
      border-radius: 10px;
      color: $blue;
    }
  }
}

/// responsive design for the avatar-assistant component

@media screen and (max-width: 768px) {
  .redirect-page {
    &-container {
      flex-direction: column;
    }
    &-content {
      padding: 1rem;
    }
  }
}
